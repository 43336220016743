.homeslider{
    display: none;
}
.nav_back{
    background-color: rgba(153, 100, 24, 1);
}
.navbar-toggler{
    color: antiquewhite;
}
.singtripbtn {
    background-color: #996418;
    border: #996418;
    margin-top: 15px;
    height: 42px;
    width: 203px;
    font-size: 20px;
}
h3.headin {
    margin-top: 17px;
    color: #996418;
    font-weight: bold;
}
button.singtripbtn.btn.btn-primary:hover {
    color: #996418;
    border: solid 1px #996418;
    background: white;
}
h3.headin {
    margin-top: 17px;
    color: #996418;
    font-weight: bold;
    float: left;
    margin-left: 3px;
    font-size: 16px;
}
input#input5 {
    height: 55px;
    background: #e7e7e7;
    margin-top: 5px;
    border: solid 1px #b6b1b1;
}
h5.comment {
    color: #86b7fe;
    float: left;
    margin-top: 16px;
    margin-left: 3px;
}

button.Proceed_button.btn.btn-primary {
    width: 231px;
    margin-top: 28px;
    height: 42px;
    background-color: #996418;
    border: #996418;
    font-size: 20px;
    margin-bottom: 10px;
    float: right;
}
.back_banner{
    background-image: url(../../public/images/BACK_BAN.jpeg);
    /* filter: brightness(0.30); */
}
.back_banner.background-image{
    filter: brightness(0.30);
}

button.Proceed_button.btn.btn-primary:hover {
    color: #996418;
    border: solid 1px #996418;
    background: white;
    font-weight: bold;
}
button.pic_btn_modal.btn.btn-primary {
    height: 55px;
    background: #e7e7e7;
    margin-top: 5px;
    border: solid 1px #b6b1b1;
    width: 100%;
    color: #706565;
    text-align: left;
}
.caret_down {
    float: right;
}
button.pic_btn_modal.btn.btn-primary:hover {
    background-color: white;
    border: solid #996418;
}
.form-check {
    min-height: 2.5rem !important;
    padding-left: 3.5em !important;
    margin-bottom: 0.125rem !important;
}
button.commentsbtn.btn.btn-primary {
    background: transparent;
    border: 0px;
    color: #a4610d;
    float: left;
    margin-top: 16px;
    margin-left: 3px;
    font-weight: 500;
    font-size: 18px;
    border: solid 1px;
    background-color: wheat;
    padding: 0px 8px;
}
.big_screen_adj {
    padding: 2% 32%;
}
.product-slide img {
    width: 100%;
}
.slick-slide > div:first-child{
    margin: 1px;
}
/* Tab container */
.tab-buttons {
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
}
.tab-buttons button {
    color: #996418;
    font-weight: 700;
    width: 150px;
}
  /* Tab button style */
  .tab-buttons button {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  /* Active tab button style */
  .tab-buttons button.active {
    background-color: #996418;
    color: #fff;
    border: 1px solid #996418;
  }
  .tab-buttons .active {
    background: radial-gradient(black, transparent);
}
  /* Tab content style */
  .tab-content {
    background-color: #fff;
    /* border: 1px solid #ccc; */
    padding: 20px;
    border-radius: 38px;
}
  
  /* Hide inactive tab content */
  /* .tab-content:not(.active) {
    display: none;
  } */
  
/* Extra Small Devices, Phones */ 
section.tab_area_bt {
    margin-top: 15px;
}
section.tab_area_bt h2 {
    color: #996418;
}
.product-slide {
    border: solid 1px #a4610d;
}
button.slick-arrow.slick-prev {
    background: #a4610d;
    border-radius: 16px;
}
button.slick-arrow.slick-next {
    background: #a4610d;
    border-radius: 16px;
}
.product-slide h3 {
    color: #a4610d;
}
.product-slide span {
    font-weight: 900;
    color: #a4610d;
    font-size: 22px;
}
.product-slide img {
    border-bottom: 1px solid;
}
.big_screen_adj_back {
    background-color: rgba(255, 255, 255, 0.5);  
    border: solid 1px #996418;
    border-radius: 10px;
}
input#input5:hover {
    background-color: white;
    border: solid #996418;
}
textarea.comnt_txt.form-control {
    margin: 4px 25px;
}
.disc_info .info_icn {
    margin-right: 5px;
    margin-left: 16px;
    color: gray;
}

.disc_info {
    font-size: 19px;
    font-weight: 700;
    color: gray;
}
.slick-slide img {
    display: block;
    height: 200px;
}
@media only screen and (max-width : 480px) {
    .homeslider{
        display: block;
    }
    button.Proceed_button.btn.btn-primary {
        width: 100%;
        font-size: 19px;

    }
    .modal-content {
        width: 85%;
        margin-top: 173px;
        margin-left: 30px;
    }
    .singtripbtn {
        width: 154px;
    }
    .big_screen_adj {
        padding: 0px 0px;
    }
    .big_screen_adj_back {
        background-color: transparent;  
        border: solid 0px #996418;
        border-radius: 10px;
    }
    .back_banner{
        background-image: none;
    }
    section.back_vehicles_section {
        display: none;
    }
    a.brand_tx.navbar-brand {
        border: solid 1px;
        border-radius: 63px;
        background-color: rgba(255, 255, 255, 0.5); ;
        width: 18%;
        height: 20%;
    }
    textarea.comnt_txt.form-control {
        margin: 0px 0px;
    }
    button.commentsbtn.btn.btn-primary {
        background: transparent;
        border: 0px;
        color: #a4610d;
        float: left;
        margin-top: 16px;
        margin-left: 3px;
        font-weight: 500;
        font-size: 18px;
    }
}
