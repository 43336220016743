.login_head {
    width: 100%;
    background: #996418;
    color: white;
    padding: 10px;
}
.backicon {
    float: left;
    margin-left: 17px;
    font-size: 27px;
    width: 0px;
}
a.bcbtn.btn.btn-primary  {
    background-color: #996418;
    border: #996418;
}

a.bcbtn.btn.btn-primary:hover {
    background-color: white;
    border: #4e3614;
    color: #996418;
    font-weight: bold;
}
.input_area {
    margin-top: 55px;
}

.otpbtn {
    background-color: #996418;
    border: #996418;
    margin-top: 40px;
    height: 49px;
    width: 40%;
    font-size: 20px;
    float: right;
}
a.otpbtn.btn.btn-primary:hover {
    background-color: white;
    border: #996418 solid 1px;
    color: #996418;
}
.whl_login {
    padding: 0px 30%;
}
.login_back{
    background-image: url(../../public/images/login_back3.jpg);
    background-size: cover; /* Optional: Adjust how the image is displayed */
    background-repeat: no-repeat; /* Optional: Prevent image repetition */
    height: 100vh;
    /* background-position-y: -355px; */
}

@media only screen and (max-width : 480px) {
    .otpbtn {
        background-color: #996418;
        border: #996418;
        margin-top: 40px;
        height: 49px;
        width: 100%;
        font-size: 20px;
    }
    .whl_login {
        padding: 0px 0px;
    }
    
}