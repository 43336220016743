.icn_ara_sedan{
    padding: 10px;

}
p.car_details {
    padding: 22px;
    font-weight: bold;
    font-size: 14px;
    color: gray;
}
.car_card {
    border: solid 1px #847a7a;
    border-radius: 10px;
    margin: 20px 0px;
    background-color: #ededed;
}
.car_icn1 {
    font-size: 23px;
    /* margin-right: 60px; */
    float: left;
}
.car_icn2 {
    font-size: 23px;
    /* margin-right: 45px; */
    float: left;
}
.car_icn3 {
    font-size: 23px;
    /* margin-right: 55px; */
    float: left;

}
.car_hd {
    font-size: 14px;
    font-weight: bold;
    color: #a4610d;
}
button.car_fea.btn.btn-primary {
    background: transparent;
    color: #a4610d;
    border: 0px ;
    float: right;
    font-size: 14px;
    width: 100%;
    font-weight: bold;
}
svg.svg-inline--fa.fa-bars {
    float: right;
}
.car_c_btn {
    border: solid 1px #847a7a;
    border-radius: 10px;
    margin: 0px;
    padding: 10px;
    background-color: white;
    border-bottom: 0px;
}
svg.svg-inline--fa.fa-arrow-right.arr_icn {
    color: #a4610d;
    font-size: 17px;
    margin-right: 10px;
}
div#example-collapse-text {
    text-align: left;
    margin-left: 27px;
    font-size: 16px;
    margin-top: 20px;
}

button.book_btn.btn.btn-primary {
    margin-top: 20px;
    width: 227px;
    height: 40px;
    background: #a4610d;
    border: 1px solid #a4610d;
    color: white;
    border-radius: 10px;
    float: right;
}
button.book_btn.btn.btn-primary:hover{
    background: white;
    color: #a4610d;
}
.mdl_btn {
    text-align: center;
    display: flex;
    justify-content: center;
}
button.pay_btn.btn.btn-primary {
    background: #a4610d;
    margin: 0px 10px;
    height: 45px;
    border: 1px solid #a4610d;
}
.cent {
    text-align: center;
}
p.succ {
    margin-bottom: 0px;
    font-weight: bold;
    font-size: 18px;
}
svg.svg-inline--fa.fa-check.succ_icon {
    font-size: 42px;
    margin-bottom: 14px;
}
.para_sedan{
    font-weight: bold;
    font-size: 14px;
    color: gray;
}
@media only screen and (max-width : 480px) {

    

button.book_btn.btn.btn-primary {
    width: 100%;
}
}