.det_area {
    border: solid 1px #a4610d;
    margin: 0px 10px;
    text-align: left;
    padding: 10px;
    background-color: #e8e8e8;
    border-radius: 10px;
    margin-top: 40px;
    display: flow-root;
}
.det_area button {
    width: 100%;
    background: #a4610d;
    color: white;
    border: solid #b15d00 1px;
}
.det_area h4 {
    color: #a4610d;
    font-size: 17px;
}
.det_area p {
    font-size: 14px;
    font-weight: bold;
    color: gray;
}
.det_box.row {
    padding: 10px 25%;
}
button.mange_btn.btn.btn-primary {
    float: right;
    width: 190px;

}

@media only screen and (max-width : 480px) {
    .det_box.row {
        padding: 0;
    }
    button.mange_btn.btn.btn-primary {
        width: 100%;
        float: none;
    
    }
}

