.policy_area h4 {
    margin: 15px 0px;
    text-align: left;
}
.policy_area p {
    text-align: left;
    margin-left: 35px;
    margin-right: 10px;

}


@media only screen and (max-width : 480px) {

    
}